<template>
  <main class="col-12">
    <DataTable
      :value="users"
      :paginator="true"
      :rows="10"
      dataKey="account.name"
      :rowHover="true"
      filterDisplay="menu"
      :filters="filters1"
      :globalFilterFields="['Account', 'Date', 'Status']"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="perPageOptions"
      responsiveLayout="scroll"
      v-model:selection="selectedUsers"
    >
      <template #header>
        <div
          class="flex justify-content-between tw-items-center flex-column sm:flex-row"
        >
          <h1 class="tw-text-2xl tw-font-bold">Deleted Accounts</h1>
          <div class="">
            <!-- 
                        <Button type="button" icon="pi pi-filter-slash" label="Hapus Filter" class="mb-2 p-button-outlined"
                            @click="clearFilter()" /> -->
            <span class="mb-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters1['global'].value"
                placeholder="Search"
                style="width: 100%"
              />
            </span>
          </div>
        </div>
      </template>
      <template #empty>
        <div
          class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
        >
          Tidak ada data yang ditemukan
        </div>
      </template>
      <template #loading>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
          Loading records, please wait...
        </div>
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column header="Account" style="min-width: 2rem">
        <template #body="{ data }">
          {{ data.account.name }}
        </template>
        <!-- <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by account" />
                </template> -->
      </Column>
      <Column header="Date">
        <template #body="{ data }">
          {{ data.date }}
        </template>
        <!-- <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by account" />
                </template> -->
      </Column>
      <Column header="Reason" style="min-width: 12rem">
        <template #body="{ data }">
          {{ data.reason }}
        </template>
      </Column>
      <Column header="Note" style="min-width: 12rem">
        <template #body="{ data }">
          {{ data.note ? data.note : `-` }}
        </template>
      </Column>
      <Column header="Status" filterField="status_name" style="min-width: 6rem">
        <template #body="{ data }">
          <Tag
            :severity="severity(data.status)"
            :value="admin_status.find((e) => e.id === data.status).value"
          >
          </Tag>
        </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by status name"
          />
        </template>
      </Column>
      <Column
        header="Actions"
        :showFilterMatchModes="false"
        :filterMenuStyle="{ width: '14rem' }"
        style="min-width: 15rem"
      >
        <template #body>
          <!-- <Button icon="pi pi-trash" class="p-2 p-button-rounded p-button-info" @click="detailData(data.id)" />
                    <Button icon="pi pi-pencil" class="p-2 ml-1 p-button-rounded p-button-warning"
                        @click="editData(data.id)" />
                    <Button label="Reset Password" icon="pi pi-refresh" class="py-2 ml-1 p-button-danger"
                        @click="confirmResetPassword(data)" /> -->
          <Button
            label="Approve"
            @click="displayConfirmation = true"
            class=""
          />
          <Button
            label="Reject"
            @click="reject"
            class="p-button-text tw-ml-2"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="displayConfirmation" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <!-- <i class="mr-3 pi pi-exclamation-triangle" style="font-size: 2rem" /> -->
        <p class="text-lg tw-font-bold">
          Apakah Anda yakin ingin menghapus akun ini?
        </p>
      </div>
      <template #footer>
        <div
          class="tw-w-full tw-flex tw-items-center"
          v-if="!loadingResetPassword"
        >
          <Button
            label="Approve"
            @click="approve"
            class="bg-red-600 tw-w-1/2 tw-flex-1 hover:bg-red-300"
          />
          <Button
            label="Cancel"
            @click="displayConfirmation = false"
            class="p-button-text tw-w-1/2 tw-flex-1"
          />
        </div>
        <ProgressSpinner
          style="width: 25px; height: 25px"
          strokeWidth="8"
          v-else
        />
      </template>
    </Dialog>
  </main>
</template>
<script setup>
import { ref, defineProps, toRefs, onMounted } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import axios from "axios";

const props = defineProps({
  perPageOptions: {
    type: Array,
    required: false,
    default() {
      return [10, 20, 50];
    },
  },
});

const { perPageOptions } = toRefs(props);

const users = ref([
  {
    account: {
      name: "KirantiLoh",
    },
    date: new Date().toLocaleDateString(),
    reason: "Mager",
    note: "",
    status: 1,
  },
  {
    account: {
      name: "JDK",
    },
    date: new Date().toLocaleDateString(),
    reason: "Mager",
    note: "",
    status: 0,
  },
  {
    account: {
      name: "KRL",
    },
    date: new Date().toLocaleDateString(),
    reason: "Mager",
    note: "",
    status: 1,
  },
]);

// const users = ref([]);

const selectedUsers = ref([]);

const filters1 = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  no: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  email: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  phone_number: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  status_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
});
const admin_status = ref([
  { id: 0, value: "Requested" },
  { id: 1, value: "Approved" },
  { id: 2, value: "Suspend" },
]);
const displayConfirmation = ref(false);

function severity(status) {
  let result = "";
  switch (status) {
    case 0:
      result = "warning";
      break;
    case 1:
      result = "success";
      break;
    default:
      result = "danger";
      break;
  }
  return result;
}

function approve() {}

function reject() {}

async function getAccounts() {
  const res = await axios.get(
    `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/notification?type="REQUEST_DELETE"`
  );
  const data = res.data;
  users.value = data.data.notifications;
}

onMounted(() => {
  getAccounts();
});
</script>
<style scoped>
:deep(.p-datatable-header) {
  background: #eff3f8;
  color: #343a40;
  padding: 1rem 0;
  font-weight: 700;
  border: none;
}

:deep(.p-datatable-thead > tr > th) {
  background: #fff;
}

:deep(.p-dialog .p-dialog-footer) {
  @apply tw-text-center;
}

:deep(.p-datatable-wrapper) {
  @apply tw-shadow tw-bg-white tw-border-0 tw-p-4 tw-px-6 tw-pb-0 tw-rounded-t-md;
}

:deep(.p-paginator) {
  @apply tw-rounded-b-md tw-rounded-t-none tw-border-0 tw-px-6 tw-shadow tw-p-4 tw-pt-0;
}

:deep(.p-column-title) {
  @apply tw-text-xl;
}

:deep(.p-datatable-tbody > tr > td) {
  @apply tw-border-0;
}
</style>
